import { applyAll } from '../../util/function';
import useStockData from './useStockData';
import { useNoPerformance } from './usePerformanceData';

const useProductsData = (...args) => {
  let combined = applyAll(useStockData, useNoPerformance);
  return combined(...args);
};

const useFullProductsCompareData = (
  locationIdA,
  locationIdB,
  locationParamsA,
  locationParamsB,
  periodId,
  currency,
  includeTaxes,
  useLLY
) => {
  const [asyncStockA] = useProductsData(
    locationIdA,
    locationParamsA,
    periodId,
    currency,
    includeTaxes,
    useLLY
  );

  const [asyncStockB] = useProductsData(
    locationIdB,
    locationParamsB,
    periodId,
    currency,
    includeTaxes,
    useLLY
  );

  const error = asyncStockA.error || asyncStockB.error;
  const loading = asyncStockA?.loading || asyncStockB?.loading;

  let data;
  if (!loading && !error) {
    data = {
      menStock: {
        totals: {
          adults: {
            menA: asyncStockA?.data.totals.adults.men,
            menB: asyncStockB?.data.totals.adults.men,
          },
        },
        chart: {
          adults: {
            labels: asyncStockA?.data?.chart.adults.labels,
            series: [
              asyncStockA?.data?.chart.adults.series[0],
              asyncStockB?.data?.chart.adults.series[0],
            ],
          },
        },
      },
      womenStock: {
        totals: {
          adults: {
            womenA: asyncStockA?.data.totals.adults.women,
            womenB: asyncStockB?.data.totals.adults.women,
          },
        },
        chart: {
          adults: {
            labels: asyncStockB?.data?.chart.adults.labels,
            series: [
              asyncStockA?.data?.chart.adults.series[1],
              asyncStockB?.data?.chart.adults.series[1],
            ],
          },
        },
      },
    };
  }

  return {
    loading,
    data,
    error,
    refetch: () => {
      asyncStockA.refetch();
      asyncStockB.refetch();
    },
  };
};

export default useFullProductsCompareData;