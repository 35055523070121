import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Header from './Header';
import BarChart from './Charts/Bar';
import LoadingBar from './LoadingBar';
import SectionSpacer from './SectionSpacer';
import { CardGrid, SalesCard } from './Cards';
import { ErrorPageOrChildren } from './Errors';
import { PageView } from '../gtag';
import ErrorBoundary from '../ErrorBoundary';
import { withTraslation } from '../data/LanguageProvider';
import useFullProductsCompareData from '../data/hooks/useFullProductsCompareData';

const GenderCards = ({ data, useLLY }) => (
  <CardGrid>
    {Object.entries(data).map(([k, v]) => (
      <SalesCard
        key={k}
        topLabel={v.formattedSum}
        middleLabel={v.formattedPercentage}
        bottomLabel={withTraslation(v.genderLabel)}
        color={k.includes('menA') ? 'primary' : 'secondary'}
        delta={v.dpy}
        useLLY={useLLY}
      />
    ))}
  </CardGrid>
);

GenderCards.propTypes = {
  data: PropTypes.shape({
    formattedSum: PropTypes.string,
    formattedPercentage: PropTypes.string,
    genderLabel: PropTypes.string,
  }),
};

const styles = theme => ({
  barChart: {
    marginTop: theme.spacing(2),
  },
  heading: {
    paddingTop: '20px',
  },
});

const ProductsStock = ({ classes, data, useLLY }) => (
  <>
    <GenderCards data={data?.totals['adults']} useLLY={useLLY} />
    {data?.chart?.['adults'] && (
      <BarChart className={classes.barChart} data={data.chart['adults']} />
    )}
    <SectionSpacer />
  </>
);

const ProductCompare = ({
  classes,
  period,
  locationA: locationAndParamsA,
  locationB: locationAndParamsB,
  userSettings,
  intl,
}) => {
  const locationIdA = locationAndParamsA.location.id;
  const locationIdB = locationAndParamsB.location.id;
  const { data, loading, error, refetch } = useFullProductsCompareData(
    locationIdA,
    locationIdB,
    locationAndParamsA.params,
    locationAndParamsB.params,
    period?.id,
    userSettings.useUsdAmount,
    userSettings.includeTaxes,
    userSettings.useLLY
  );

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <PageView
      params={{
        period_id: period?.id,
        location_id: locationAndParamsA.location.name,
        channel: locationAndParamsA.params.channel,
      }}
    >
      <div className={classes.heading}>
        <Header title={intl.formatMessage({ id: 'gender_mix' })} />
      </div>
      <ErrorPageOrChildren
        message={intl.formatMessage({ id: 'not_load_sales' })}
        errors={[error]}
        onClickRetry={refetch}
      >
        <>
          {data?.menStock && (
            <ErrorBoundary>
              <ProductsStock
                classes={classes}
                data={data?.menStock}
                useLLY={userSettings.useLLY}
              />
            </ErrorBoundary>
          )}
          {data?.womenStock && (
            <ErrorBoundary>
              <ProductsStock
                classes={classes}
                data={data?.womenStock}
                useLLY={userSettings.useLLY}
              />
            </ErrorBoundary>
          )}
        </>
      </ErrorPageOrChildren>
    </PageView>
  );
};

export default injectIntl(withStyles(styles)(ProductCompare));