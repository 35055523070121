export const KPIList = [
    'aos_percentage',
    'blanks_pc9',
    'blanks_total',
    'conversion_percentage',
    'e_receipt_capture',
    'weeks_of_coverage',
    'transaction_count',
    'traffic',
    'store_count_traffic',
    'store_count_sales',
    'sales_vs_traffic_with_tax',
    'sales_vs_traffic',
    'sales_units_net',
    'sales_units_gross',
    'sales_over_traffic_with_tax',
    'sales_over_traffic',
    'salesfloor_instock',
    'retail_sales_returns_value_with_tax',
    'retail_sales_returns_value',
    'retail_sales_returns_percentage_with_tax',
    'retail_sales_returns_percentage',
    'retail_sales_return_units',
    'retail_sales_net_value_with_tax',
    'retail_sales_net_margin_percentage',
    'retail_sales_net_aur_with_tax',
    'retail_sales_net_atv_with_tax',
    'retail_sales_net_value',
    'retail_sales_net_upt',
    'retail_sales_net_margin',
    'retail_sales_net_aur',
    'retail_sales_net_atv',
    'retail_sales_gross_value_with_tax',
    'retail_sales_gross_value',
    'net_sales_with_tax_by_gross_leased_area',
    'net_sales_by_gross_leased_area',
    'ioh_value_eop',
    'ioh_units_eop',
    'adoption_percent',
  ];