import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Collapse } from '@material-ui/core';
import PerformancePill from './PerformancePill';
import { Text2 } from '../typography';
import { withTraslation } from '../data/LanguageProvider';
import { injectIntl } from 'react-intl';
import KPiGraphTabs from './Cards/KPiGraphTabs';
import { TrendsLineIcon } from './Icons';
import {convertTextInTranslationKey} from "../data/utils/transforms";
import { GTAG_EVENT } from '../gtag';

const styles = theme => ({
  root: {
    '& > *': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& > *:last-child': {
      borderBottom: 'none',
    },
  },
  metricRow: {
    // paddingTop: theme.spacing(1.5),
    // paddingBottom: theme.spacing(1) + 1,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1) + 1}px`,
    margin: theme.spacing(1.5, 0),
    borderRadius: 4,
    '& p': {
      display: 'flex',
      alignItems: 'flex-start',
      '& svg': {
        marginRight: '4px',
        float: ' right',
        opacity: 'unset',
        // background: '#e3dbdb',
        // borderRadius: '50%',
        cursor: 'pointer',
      },
    },
  },
  valueAndDpyRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 3,
    justifyContent: 'space-between',
  },
  pill: {
    whiteSpace: 'normal',
    minHeight: 24,
    height: 'unset',
    minWidth: 'unset',
  },
  arrowIcon: {
    position: 'absolute',
    right: '20px',
  },
});

const KPIList = ({
  metrics,
  revenueStream,
  classes,
  useLLY,
  intl,
  useUsdAmount,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const pillLabel = useLLY ? 'vsPPY' : 'vsPY';
  const handleExpandClick = (item, label) => {
    setExpanded(item);
    if (item) {
      GTAG_EVENT({ view: 'trends_view', category: 'Trendlines', label: label });
    }
  };
  return (
    <div className={classes.root}>
      {metrics.map(m => {
        const trendsLength = m.formattedTrends
          ? Object.keys(m.formattedTrends).length
          : 0;
        let metricId = m.id;
        // Check revenueStream and update labels accordingly
        if (['SFS_SALES', 'BOPIS_SALES'].includes(revenueStream)) {
          metricId = convertTextInTranslationKey(revenueStream);
        }
        return (
          <div key={m.id} className={classes.metricRow}>
            <Text2 gray component="p">
              {m.hasTrends && trendsLength > 0 && (
                <TrendsLineIcon
                  fontSize="large"
                  onClick={() =>
                    handleExpandClick(expanded === m.id ? null : m.id)
                  }
                />
              )}
              {withTraslation(metricId)}
            </Text2>
            <div className={classes.valueAndDpyRow}>
              <Text2 component="span">
                {useLLY && m.formattedLLYValue
                  ? m.formattedLLYValue
                  : m.formattedValue}
              </Text2>
              {m.dpy ? (
                <PerformancePill
                  classes={{ root: classes.pill }}
                  delta={m.dpy}
                  size="medium"
                  showArrow={false}
                  label={intl.formatMessage({ id: pillLabel })}
                />
              ) : (
                <div className={classes.pill} />
              )}
            </div>
            <Collapse
              in={trendsLength > 0 ? expanded === m.id : false}
              timeout="auto"
              unmountOnExit
              // className={classes.content}
            >
              <KPiGraphTabs
                data={m.formattedTrends}
                dots={false}
                dataKeyA="value"
                dataKeyB="value_py"
                strokeA="#8884d8"
                strokeB="#D0D0D0"
                format={m.format}
                useUsdAmount={useUsdAmount}
              />
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

KPIList.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      dpy: PropTypes.shape({
        delta: PropTypes.number,
        formattedDelta: PropTypes.string.isRequired,
        sentiment: PropTypes.oneOf(['good', 'bad', 'neutral']),
      }).isRequired,
    }).isRequired
  ),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'LriKPIList' })(injectIntl(KPIList));
