import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { Select, SelectDrawer } from '../../components/Select';
import { noop } from '../../util/function';
import { usePreferencesManager } from '../user-preferences';
import usePeriodData from '../hooks/usePeriodData';
import { subMilliseconds, subDays, subMonths, subYears } from 'date-fns';
import LoadingBar from '../../components/LoadingBar';
import { useRetryPeriod } from '../hooks/useRetryPeriod';
import DateRangePicker from './DateRangePicker';
import { withTraslation } from '../../data/LanguageProvider';

const periodKeys = {
  pd: 'day',
  wtd: 'week',
  mtd: 'month',
  qtd: 'quarter',
  ytd: 'year',
  st: 'specificTimeFrame',
};

const scrollToBottom = () => {
  const element = document.getElementById('datePickerContainer');
  if (element) {
    element.scrollIntoView();
  }
};

const formatTimestamp = (dateTime) => moment(dateTime).format('YYYYMMDD');

const getInitialPeriod = (items, pm) => {
  if (!items) return null;
  const storedPreferencePeriod = pm.get('period');
  const preferencePeriod = storedPreferencePeriod === 'st' ? 'pd' : storedPreferencePeriod;
  return items.find(x => x.id === preferencePeriod) || items[0];
};

const convertTimeStamp = (date = new Date()) => {
  const utc =
    new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000;
  return utc;
};

export const findPeriod = (periodList, date) => {
  // API data is inclusive lower bound, exclusive upper bound.
  // TODO: binary search
  const test =
    periodList.find(period => {
      return (
        convertTimeStamp(period.startDate) <= convertTimeStamp(date) &&
        convertTimeStamp(period.endDate) > convertTimeStamp(date)
      );
    }) || {};
  return test;
};

const PeriodsShell = ({ children, onClose = noop }) => {
  const pm = usePreferencesManager();

  const { data: periods, loading, error } = usePeriodData();
  const location = useLocation();
  const [drawer, setDrawer] = useState(false);
  const fixedPeriodOptions = [
    { id: 'pd', label: 'Prior Day' },
    { id: 'wtd', label: 'Week to Date' },
    { id: 'mtd', label: 'Month to Date' },
    { id: 'qtd', label: 'Quarter to Date' },
    { id: 'ytd', label: 'Year to Date' },
    { id: 'st', label: 'TimeFrame', disabled: ['/products', '/compare'].includes(location.pathname) },
  ];
  const [selected, setSelected] = useState(() =>
    getInitialPeriod(fixedPeriodOptions, pm)
  );
  const [selectSelected, setSelectSelected] = useState(() =>
    getInitialPeriod(fixedPeriodOptions, pm)
  );
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    if (selectSelected.id === 'st') {
      scrollToBottom();
    }
  }, [selectSelected.id]);

  useEffect(() => {
    if (selectSelected.id === 'st' && ['/products', '/compare'].includes(location.pathname)) {
      handleSelect({ id: 'pd', label: 'Prior Day' });
    }
  // eslint-disable-next-line
  }, [location]);

  const toggleSelect = () => {
    setDrawer(true);
  };

  const { retryPeriod, setRetryPeriod } = useRetryPeriod();
  const handleSelect = item => {
    setSelectSelected(item);
    if (item.id !== 'st') {
      handleDateRangeSelect(item);
    }
  };

  const handleDateRangeSelect = (item = selectSelected) => {
    setSelected(item);
    setRetryPeriod(false);
    pm.set('period', item.id);
    onClose();
    setDrawer(false);
  };

  const getSelectedPeriod = () => {
    const key = periodKeys[selected.id];
    const subAmount = key => {
      switch (key) {
        case 'day':
          return subDays(new Date(), 2);
        case 'week':
          return subDays(new Date(), 7);
        case 'month':
          return subMonths(new Date(), 1);
        case 'quarter':
          return subMonths(new Date(), 3);
        case 'year':
          return subYears(new Date(), 1);
        default:
          return subDays(new Date(), 1);
      }
    };
    // TODO: Timezone handling.
    const date = retryPeriod ? subAmount(key) : subDays(new Date(), 1);
    let period = {};
    if (selected.id === 'st') {
      period = findPeriod(periods.day, date);

      const [startDate, endDate] = dateRange;
      period.pyId = 'timeframe';
      period.id = 'timeframe';
      period.selectedEndDate = formatTimestamp(endDate);
      period.selectedStartDate = formatTimestamp(startDate);
    } else {
      period = findPeriod(periods[key], date);
    }
    // TODO: Proper handling when there are no matching periods.
    const eDate = subMilliseconds(period.endDate, 1000);
    period.currentDate = retryPeriod ? eDate : period.currentDate;
    return period;
  };

  const getTodaysPeriod = () => {
    const date = new Date();
    const period = findPeriod(periods.day, date);
    return period;
  };

  const getPriorDayPeriod = () => {
    const date = retryPeriod ? subDays(new Date(), 2) : subDays(new Date(), 1);
    const priorDayPeriod = findPeriod(periods.day, date);
    const eDate = subMilliseconds(priorDayPeriod.endDate, 1000);
    priorDayPeriod.currentDate = retryPeriod ? eDate : priorDayPeriod.currentDate;
    return priorDayPeriod;
  }

  let err = error;

  return (
    <>
      {err && children(null, null, () => null, err)}
      {loading && <LoadingBar />}
      {!err && periods && (
        <>
          {children(selected, toggleSelect, getSelectedPeriod, getTodaysPeriod, getPriorDayPeriod)}
          <SelectDrawer
            open={drawer}
            title={withTraslation('select_period')}
            onClose={() => {
              onClose();
              setDrawer(false);
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <Select
                items={fixedPeriodOptions}
                selected={selectSelected}
                onSelect={handleSelect}
              />
              {selectSelected.id === 'st' && (
                <div id="datePickerContainer">
                  <DateRangePicker
                    selectedStartDate={dateRange[0]}
                    selectedEndDate={dateRange[1]}
                    onDateRangeSelect={(startDate, endDate) => setDateRange([startDate, endDate])}
                    closeDrawer={handleDateRangeSelect}
                  />
                </div>
              )}
            </div>
          </SelectDrawer>
        </>
      )}
    </>
  );
};

PeriodsShell.propTypes = {
  onClose: PropTypes.func,
};

export default PeriodsShell;
